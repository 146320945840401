import './assets/css/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './assets/css/style.scss'

import Footer from './components/partials/Footer';
import Nav from './components/partials/Nav';
import SideBar from './components/partials/SideBar';
import { Navigate, RouterProvider, useNavigate } from 'react-router-dom';
import ProjectRouter from './components/router/ProjectRouter';
import { useEffect, useState } from 'react';
import PublicRouter from './components/router/PublicRouter';
import axios from 'axios';
import './AxiosInterceptor'; // Assurez-vous que le chemin est correct


function App() {
  //const navigate = useNavigate();
  const [auth, setAuth] = useState('');


  useEffect(() => {
    if (localStorage.token && localStorage.token !== undefined) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
      setAuth(true);
      console.log(" authentifié");
      console.log(auth);

    } else {
      setAuth(false);
      console.log("non authentifié");
      console.log(auth);
      //window.location.reload();
      //window.location.href="/";
    }
  }, [auth])

 if (localStorage.token===undefined) {
  //console.log(accountService.isLogged)
  //alert('');
  console.log('user non connecté - connecte toi !');
  //window.location.reload();
  //return <Navigate to="/"/>
  //window.location.href="/";
  //window.location.href = window.location.origin + "/";
}

  return (
    <>
   
    {localStorage.token===undefined ? <RouterProvider router={PublicRouter}/>:<RouterProvider router={ProjectRouter}/>}
      
        

    </>
  );
}

export default App;
