import React from 'react';
import loader from '../../../assets/img/loader.svg'

const Loader = () => {
    return (
        <div>
            <img src={loader} alt={'Loader'} className={'loader'}/>
        </div>
    );
};

export default Loader;