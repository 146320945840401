import axios from 'axios';
import React, { useState } from 'react';
import Constants from '../../../Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';



const Profile = () => {
  // Supposez que vous ayez les informations de l'utilisateur connecté dans localStorage
  const userEmail = localStorage.getItem('email');
  const userName = localStorage.getItem('name');
  const userPhoto = localStorage.getItem('photo');
  const userPhone = localStorage.getItem('phone');
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const [input, setInput] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    setInput((prevInput) => ({ ...prevInput, [e.target.name]: e.target.value }));
  };

  const handleChangePassword = () => {
    // Vérifiez si le nouveau mot de passe correspond à la confirmation
    if (input.newPassword !== input.confirmPassword) {
      setErrors({ confirmPassword: ['Le nouveau mot de passe ne correspond pas à la confirmation.'] });
      return;
    }

    // Envoyez une requête au backend pour changer le mot de passe
    axios
      .post(`${Constants.BASE_URL}/change-password`, input)
      .then((res) => {
        // Traitez la réponse du backend (par exemple, affichez un message de succès)
        console.log(res.data);
        // Affichez une alerte de succès
        toast.success(res.data.message);
        // Redirigez l'utilisateur vers la page d'accueil
        navigate('/');


      })
      .catch((error) => {
        // En cas d'erreur, gérer les erreurs
        if (error.response && error.response.status === 422 && error.response.data.errors) {
          setErrors(error.response.data.errors);
        }
      });
  };

  return (
    <div className='container-fluid' id={'profile'} style={{marginTop:70}}>
      <div className='row'>
        <div className='col-12 col-md-6 offset-md-3'>
          <div className='car'>
            <center><h4 className='para'>Modifier Mot de Passe</h4></center>
            <div className='card-body'>
            <label className='w-100 mt-4'>
                <p className='para'><i className='fa fa-lock'></i> Mot de Passe Actuel</p>
                <div className='input-group'>
                <input
                    className={errors.currentPassword !== undefined ? 'form-control is-invalid mt-2' : 'form-control mt-2'}
                    type={showCurrentPassword ? 'text' : 'password'}
                    name='currentPassword'
                    value={input.currentPassword || ''}
                    onChange={handleInput}
                    autoComplete='off'
                  />
                  <button
                    className='btn btn-outline-secondary'
                    type='button'
                    onClick={toggleShowCurrentPassword}
                    style={{ background: '#ffffff', height: 43, marginTop: 8, color: '#000000' }}
                  >
                    <i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                  </button>
                </div>
                <p className='login-error-msg'><small>{errors.currentPassword !== undefined ? errors.currentPassword[0] : null}</small></p>
              </label>
              <label className='w-100 mt-4'>
                <p className='para'><i className='fa fa-key'></i> Nouveau Mot de Passe</p>
                <div className='input-group'>
                <input
                    className={errors.newPassword !== undefined ? 'form-control is-invalid' : 'form-control'}
                    type={showNewPassword ? 'text' : 'password'}
                    name='newPassword'
                    value={input.newPassword || ''}
                    onChange={handleInput}
                    autoComplete='off'
                  />
                  <button
                    className='btn btn-outline-secondary'
                    type='button'
                    onClick={toggleShowNewPassword}
                    style={{ background: '#ffffff', color: '#000000' }}
                  >
                        <i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                  </button>              
                </div>
                <p className='login-error-msg'><small>{errors.newPassword !== undefined ? errors.newPassword[0] : null}</small></p>
              </label>
              <label className='w-100 mt-4'>
                <p className='para'><i className='fa fa-key'></i> Confirmer le Nouveau Mot de Passe</p>
                <div className='input-group'>
                <input
                    className={errors.confirmPassword !== undefined ? 'form-control is-invalid' : 'form-control'}
                    type={showConfirmPassword ? 'text' : 'password'}
                    name='confirmPassword'
                    value={input.confirmPassword || ''}
                    onChange={handleInput}
                    autoComplete='off'
                  />
                  <button
                    className='btn btn-outline-secondary'
                    type='button'
                    onClick={toggleShowConfirmPassword}
                    style={{ background: '#ffffff', color: '#000000' }}
                  >
                        <i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                  </button>
                </div>
                <p className='login-error-msg'><small>{errors.confirmPassword !== undefined ? errors.confirmPassword[0] : null}</small></p>
              </label>
              <div className=' mt-4 d-flex justify-content-end'>
                <button className='login_btn btn btn-outline-warning' onClick={handleChangePassword} dangerouslySetInnerHTML={{ __html: isLoading ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Changer le mot de passe...' : 'Changer le mot de passe' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
