import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Error403 = (props) => {
    const navigate = useNavigate();

    // Utiliser useEffect pour déclencher la redirection automatique
    useEffect(() => {
        const redirectTimeout = setTimeout(() => {
            // Rediriger vers la page d'accueil après un certain délai (par exemple, 3 secondes)
            navigate('/');
        }, 3000); // Délai en millisecondes, ajustez selon vos besoins

        // Nettoyer le timeout lors du démontage du composant
        return () => clearTimeout(redirectTimeout);
    }, [navigate]);

    return (
        <>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-md-12 text-center mt-5'>
                        <h1 className={'text-danger'}>Vous n'avez pas accès à cette ressource !</h1>
                        <p>Vous serez redirigé vers la page d'accueil automatiquement.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Error403;
