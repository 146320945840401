// Master.js
//import CustomErrorBoundary from './CustomErrorBoundary';
import React from 'react';
import Nav from '../partials/Nav';
import SideBar from '../partials/SideBar';
import Footer from '../partials/Footer';
import { Outlet } from 'react-router-dom';
import CustomErrorBoundary from '../CustomErrorBoundary';

const Master = () => {
  return (
    <CustomErrorBoundary>
      {/* Le reste du contenu du composant Master */}
      <>
                <Nav/>
                <div id="layoutSidenav">
                        <SideBar/>
                        <div id="layoutSidenav_content">
                            <main>
                                    <div class="container-fluid px-4">
                                        <Outlet/> 
                                    </div>
                            </main>
                            <Footer/>
                        </div>
                </div>  
        </>
    </CustomErrorBoundary>
  );
};

export default Master;



